
import React, { useState, useEffect } from 'react';
import './AddDrawer.css';
import BodyLargeMedium from '../designSystem/BodyLargeMedium';
import closeIcon from "../../assets/images/closeIcon.svg";
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium';
import tickIcon from "../../assets/images/tickIcon.svg";
import AddEventCard from './AddEventCard';
import deleteIcon_drawer from "../../assets/images/deleteIcon_drawer.svg";
import addIcon from "../../assets/images/addIcon.svg";
import { useDispatch, useSelector } from 'react-redux';
import { getTaskPracticeLocations } from '../../actions/userActions/tasks/taskAction';
import { addEmployeePlan, deletePlanVisits, updateEmployeePlan } from '../../actions/userActions/EmployeePlanAction';
import { getVisitProfileDetail } from '../../actions/userActions/VisitProfileAction';
import { getEmployee } from '../../actions/userActions/employeeAction';
import DeleteVisitModal from './DeleteVisitModal';
import { getCities } from '../../actions/userActions/cityAction';

const AddDrawer = ({ isOpen, onClose, day, events }) => {
    const permissions = JSON.parse(localStorage.getItem('permissionsList'));
    const [isDeleteVisitModalVisible, setIsDeleteVisitModalVisisble] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.result[0]?.user.id;
    const dispatch = useDispatch();
    const [isVisitProfileId, setIsVisitProfileId] = useState(false);
    const [disable, setDisable] = useState(false);
    const [visitProfileId, setVisitProfileId] = useState(null);
    const [errors, setErrors] = useState([]);
    const [deletePlanDetail, setDeletePlanDetail] = useState([]);
    const [deletePlanVisitsIds, setDeletePlanVisitsIds] = useState([]);
    const [updatedFormValues, setUpdatedFormValues] = useState();

    const practiceLocations = useSelector(state => state.tasks_reducer.taskPracticeLocations);
    const addPlanStatus = useSelector(state => state.employeePlan_reducer.addEmployeePlan);
    const updateEmployeePlanStatus = useSelector(state => state.employeePlan_reducer.updateEmployeePlan);
    const deletePlanVisitsStatus = useSelector(state => state.employeePlan_reducer.deletePlanVisits);

    const userDetail = useSelector((state) => state.employees_reducer.employee);
    const initialValues = {
        employeePlanId: 0,
        employeeId: userId,
        month: null,
        year: null,
        employeePlanDate: {
            employeePlanDateId: 0,
            planDate: null,
            planDetails: [
                {
                    id: 0,
                    cityId: null,
                    startTime: null,
                    endTime: null,
                    practiceLocationId: null,
                    doctorId: null,
                    headOffice: false
                }
            ]
        }
    }
    const [isDeleteActive, setIsDeleteActive] = useState(false);
    const [formValues, setFormValues] = useState(initialValues);

    const addAnotherPlanDetail = () => {
        if (permissions && permissions.includes('CanAddPlannerTime') && permissions.includes('CanAddPlannerCity')) {
            setFormValues((prevValues) => ({
                ...prevValues,
                employeePlanDate: {
                    ...prevValues.employeePlanDate,
                    planDetails: [
                        ...prevValues.employeePlanDate.planDetails,
                        {
                            id: 0,
                            cityId: user?.result[0]?.user?.cityId,
                            startTime: null,
                            endTime: null,
                            practiceLocationId: null,
                            doctorId: null,
                            headOffice: false
                        }
                    ]
                }
            }));
        } else {
            setFormValues((prevValues) => ({
                ...prevValues,
                employeePlanDate: {
                    ...prevValues.employeePlanDate,
                    planDetails: [
                        ...prevValues.employeePlanDate.planDetails,
                        {
                            id: 0,
                            cityId: null,
                            startTime: null,
                            endTime: null,
                            practiceLocationId: null,
                            doctorId: null,
                            headOffice: false
                        }
                    ]
                }
            }));
        }
        setDisable(false);
    };

    // Update formValues when 'day' becomes available
    useEffect(() => {
        if (day) {
            const formattedDate = new Date(day.year, day.month - 1, day.day + 1).toISOString();
            if (permissions && permissions.includes('CanAddPlannerTime') && permissions.includes('CanAddPlannerCity')) {
                setFormValues((prevValues) => ({
                    ...prevValues,
                    month: day.month,
                    year: day.year,
                    employeePlanDate: {
                        ...prevValues.employeePlanDate,
                        planDate: formattedDate,
                        planDetails: [
                            {
                                id: 0,
                                cityId: user?.result[0]?.user?.cityId,
                                startTime: null,
                                endTime: null,
                                practiceLocationId: null,
                                doctorId: null,
                                headOffice: false
                            }
                        ]
                    }
                }));
            } else {
                setFormValues((prevValues) => ({
                    ...prevValues,
                    month: day.month,
                    year: day.year,
                    employeePlanDate: {
                        ...prevValues.employeePlanDate,
                        planDate: formattedDate
                    }
                }));
            }
            setDisable(false);
        }
    }, [day]);

    useEffect(() => {
        if (isOpen && events && events.planDetails && events.planDetails.length > 0) {
            setFormValues((prevValues) => ({
                ...prevValues,
                employeePlanId: events.employeePlanId,
                employeePlanDate: {
                    ...prevValues.employeePlanDate,
                    planDate: events.planDate,
                    employeePlanDateId: events.id,
                    planDetails: [
                        ...events.planDetails.map((detail) => ({
                            id: detail.id || 0,
                            cityId: detail.cityId || null,
                            startTime: detail.startTime || null,
                            endTime: detail.endTime || null,
                            practiceLocationId: detail.practiceLocationId || null,
                            doctorId: detail.doctorId || null,
                            headOffice: detail.headOffice || false
                        }))
                    ]
                }
            }));
            setDisable(true);
        }
    }, [events, day, isOpen]);

    const formattedDate = day
        ? new Intl.DateTimeFormat('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        }).format(new Date(day.year, day.month - 1, day.day))
        : '';

    useEffect(() => {
        if (user && user.result[0]?.user) {
            if (visitProfileId && isVisitProfileId) {
                dispatch(getVisitProfileDetail(visitProfileId));
            } else if (!user.visitProfileId && !isVisitProfileId && !permissions.includes('CanViewPlannerCity')) {
                dispatch(getTaskPracticeLocations(user.result[0].user.cityId));
            }
        }
    }, [userId, dispatch, isVisitProfileId, visitProfileId]);

    const updatePlanDetail = (index, updatedDetail) => {
        setFormValues((prevValues) => {
            const updatedPlanDetails = [...prevValues.employeePlanDate.planDetails];
            updatedPlanDetails[index] = {
                ...updatedPlanDetails[index],
                ...updatedDetail,
            };
            return {
                ...prevValues,
                employeePlanDate: {
                    ...prevValues.employeePlanDate,
                    planDetails: updatedPlanDetails,
                },
            };
        });
        setDisable(false);
        setErrors([]);
    };

    // Validation function
    const validatePlanDetails = () => {
        const newErrors = [];
        const planDetails = formValues.employeePlanDate.planDetails;

        planDetails.forEach((detail, index) => {
            const detailErrors = {};

            // Basic validation checks
            if (permissions && permissions.includes('CanAddPlannerLocation') && !detail.practiceLocationId) {
                detailErrors.practiceLocationId = 'Practice Location is required';
            }
            if (permissions && permissions.includes('CanAddPlannerDoctor') && !detail.doctorId) {
                detailErrors.doctorId = 'Doctor is required';
            }
            if (permissions && permissions.includes('CanAddPlannerTime') && !detail.startTime) {
                detailErrors.startTime = 'Start time is required';
            }
            if (permissions && permissions.includes('CanAddPlannerTime') && !detail.endTime) {
                detailErrors.endTime = 'End time is required';
            }
            if (permissions && permissions.includes('CanAddPlannerTime') && detail.startTime && detail.endTime && detail.startTime >= detail.endTime) {
                detailErrors.endTime = 'End time invalid';
            }
            if (permissions && permissions.includes('CanAddPlannerCity') && !detail.cityId && !detail.headOffice) {
                detailErrors.cityId = 'City is required';
            }

            // Overlap validation
            planDetails.forEach((otherDetail, otherIndex) => {
                if (index !== otherIndex && otherDetail.startTime && otherDetail.endTime) {
                    const otherStart = otherDetail.startTime;
                    const otherEnd = otherDetail.endTime;

                    // Check for start time overlap
                    if (detail.startTime >= otherStart && detail.startTime < otherEnd) {
                        detailErrors.startTime = 'Start time overlap';
                    }

                    // Check for end time overlap
                    if (detail.endTime > otherStart && detail.endTime <= otherEnd) {
                        detailErrors.endTime = 'End time overlap';
                    }

                    // Check for complete overlap (current detail spans the other detail)
                    if (detail.startTime <= otherStart && detail.startTime >= otherEnd) {
                        detailErrors.startTime = 'Start time overlap';
                        detailErrors.endTime = 'End time overlap';
                    }
                }
            });

            if (Object.keys(detailErrors).length > 0) {
                newErrors[index] = detailErrors;
            }
        });

        setErrors(newErrors);
        return newErrors.length === 0; // Return true if no errors
    };


    // Handle save
    const handleSave = () => {
        if (formValues && formValues.employeePlanId !== 0 && formValues.employeePlanDate.planDetails[0].cityId === "clear") {
            const updatedDeleteFormValues = {
                ...formValues,
                employeePlanDate: {
                    ...formValues.employeePlanDate,
                    planDetails: [],
                },
            };
            dispatch(updateEmployeePlan(updatedDeleteFormValues));
        }
        if (validatePlanDetails()) {
            if (formValues && formValues.employeePlanId && formValues.employeePlanId != 0) {
                dispatch(updateEmployeePlan(formValues));
            } else {
                dispatch(addEmployeePlan(formValues));
            }
        }
    };

    const handleClose = () => {
        setFormValues(initialValues);
        setErrors([]);
        setUpdatedFormValues([]);
        setDeletePlanDetail([]);
        setIsDeleteActive(false);
        onClose();
    };
    useEffect(() => {
        if ((addPlanStatus && addPlanStatus !== "isLoading" && addPlanStatus === true) || (updateEmployeePlanStatus && updateEmployeePlanStatus !== "isLoading" && updateEmployeePlanStatus === true)) {
            handleClose();
            setIsDeleteVisitModalVisisble(false);
            setDeletePlanDetail([]);
            setIsDeleteActive(false);
        }
    }, [addPlanStatus, updateEmployeePlanStatus]);

    useEffect(() => {
        if (deletePlanVisitsStatus && deletePlanVisitsStatus !== "isLoading" && deletePlanVisitsStatus === true) {
            setFormValues(updatedFormValues);
            setIsDeleteVisitModalVisisble(false);
            setIsDeleteActive(false);
            setDeletePlanDetail([]);
        }
    }, [deletePlanVisitsStatus]);

    useEffect(() => {
        if (userId) {
            dispatch(getEmployee(userId));
        }
        if (permissions && permissions.includes('CanAddPlannerCity')) {
            const cityBody = {
                filter: {
                    textSearch: "",
                    countryId: "1"
                },
                pageNumber: -1,
                pageSize: 0,
            };
            dispatch(getCities(cityBody));
        }
    }, [userId]);
    useEffect(() => {
        if (userDetail && userDetail !== "isLoading" && userDetail.length !== 0) {
            if (userDetail && userDetail.visitProfileId) {
                setIsVisitProfileId(true);
                setVisitProfileId(userDetail.visitProfileId);
            }
        }
    }, [userDetail]);
    const onDeleteVisitModalClose = () => {
        setIsDeleteVisitModalVisisble(!isDeleteVisitModalVisible);
    };

    const handleToggleCheckbox = (index, isChecked) => {
        setDeletePlanDetail((prevState) => {

            if (isChecked) {
                return [...prevState, index];
            } else {
                return prevState.filter((item) => item !== index);
            }
        });
    };
    const handleDeleteConfirm = () => {
        if (deletePlanVisitsIds && deletePlanVisitsIds.length !== 0) {
            dispatch(deletePlanVisits(deletePlanVisitsIds));
        } else if (updatedFormValues && updatedFormValues.length !== 0) {
            setFormValues(updatedFormValues);
            setIsDeleteVisitModalVisisble(false);
            setIsDeleteActive(false);
            setDeletePlanDetail([]);
        }
    };
    const handleDelete = () => {
        if (deletePlanDetail.length > 0) {
            if (
                formValues &&
                formValues.employeePlanDate &&
                formValues.employeePlanDate.planDetails
            ) {
                const deletePlanVisitsIds = [];
                formValues.employeePlanDate.planDetails.forEach((item, index) => {
                    if (deletePlanDetail.includes(index) && item.id !== null && item.id !== 0) {
                        deletePlanVisitsIds.push(item.id);
                    }
                })
                const updatedPlanDetails = formValues.employeePlanDate.planDetails.filter(
                    (_, index) => !deletePlanDetail.includes(index)
                );
                if (updatedPlanDetails && updatedPlanDetails.length === 0) {
                    setUpdatedFormValues({
                        ...formValues,
                        employeePlanId: 0,
                        employeePlanDate: {
                            ...formValues.employeePlanDate,
                            employeePlanDateId: 0,
                            planDetails: updatedPlanDetails,
                        },
                    });
                } else {
                    setUpdatedFormValues({
                        ...formValues,
                        employeePlanDate: {
                            ...formValues.employeePlanDate,
                            planDetails: updatedPlanDetails,
                        },
                    });
                }
                setDeletePlanVisitsIds(deletePlanVisitsIds);
                setIsDeleteVisitModalVisisble(true);
            }
        }
    };

    return (
        <>
            {/* Backdrop */}
            <div
                className={`add-drawer-backdrop ${isOpen ? 'open' : ''}`}
                onClick={handleClose}
            ></div>

            {/* Drawer */}
            <div className={`add-drawer-container ${isOpen ? 'open' : ''}`}>

                <div className="add-drawer-header">
                    <BodyLargeMedium text={formattedDate} color='#384153' />
                    <button
                        disabled={addPlanStatus === "isLoading" || updateEmployeePlanStatus === "isLoading" || formValues.employeePlanDate?.planDetails?.length === 0}
                        className={`save-button ${disable ? 'disabled' : ''}`}
                        onClick={handleSave}
                    >
                        <img src={tickIcon} alt="tick icon" />
                        <BodyExtraSmallMedium text="Save" color="#FFFFFF" />
                    </button>
                    {(permissions && !permissions.includes('CanAddPlannerTime')) || isDeleteActive && (
                        <button className='close-icon-button' onClick={() => setIsDeleteActive(false)}>
                            <img src={closeIcon} />
                        </button>
                    )}
                    {permissions && permissions.includes('CanAddPlannerLocation') && permissions.includes('CanAddPlannerTime') && !isDeleteActive && (
                        <button className='delete-icon-button' onClick={() => setIsDeleteActive(true)}>
                            <img src={deleteIcon_drawer} />
                        </button>
                    )}
                </div>
                <div className='add-drawer-main'>
                    {formValues.employeePlanDate.planDetails.map((planDetail, index) => (
                        <AddEventCard
                            key={index}
                            isDeleteActive={isDeleteActive}
                            planDetail={planDetail}
                            practiceLocations={practiceLocations}
                            isVisitProfileId={isVisitProfileId}
                            onUpdatePlanDetail={updatePlanDetail}
                            index={index}
                            errors={errors[index] || {}}
                            formValues={formValues}
                            isChecked={deletePlanDetail.includes(index)}
                            onToggleCheckbox={handleToggleCheckbox}
                        />
                    ))}
                    {permissions && permissions.includes('CanAddPlannerTime') && permissions.includes('CanAddPlannerLocation') && !isDeleteActive && (
                        <button className='add-another-button' onClick={addAnotherPlanDetail}>
                            <img src={addIcon} />
                            <BodyExtraSmallMedium text='Add another' color='#1E293B' />
                        </button>
                    )}
                </div>
                {isDeleteActive && (
                    <div className='footer-button-container'>
                        <button className='cancel-button'
                            onClick={() => {
                                setIsDeleteActive(false);
                                setDeletePlanDetail([]);
                            }}
                        >
                            <BodyExtraSmallMedium text='Cancel' color='#1E293B' />
                        </button>
                        <button
                            className={`delete-button ${deletePlanDetail.length === 0 ? 'opacity-70 cursor-not-allowed' : ''}`}
                            onClick={handleDelete} disabled={deletePlanDetail.length === 0}>
                            <BodyExtraSmallMedium text='Delete' color='#FFFFFF' />
                        </button>
                    </div>
                )}
            </div>
            <DeleteVisitModal isLoading={deletePlanVisitsStatus === "isLoading"} onDeleteClick={handleDeleteConfirm} isVisible={isDeleteVisitModalVisible} onClose={onDeleteVisitModalClose} />
        </>
    );
};

export default AddDrawer;
